import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"datenschutz"} />
		<Helmet>
			<title>
				Forygia Pub
			</title>
			<meta name={"description"} content={"Wo sich sportliche Leidenschaften und kulinarische Genüsse vereinen!"} />
			<meta property={"og:title"} content={"Forygia Pub"} />
			<meta property={"og:description"} content={"Wo sich sportliche Leidenschaften und kulinarische Genüsse vereinen!"} />
			<meta property={"og:image"} content={"https://forygia.com/img/home-1.jpg"} />
			<link rel={"shortcut icon"} href={"https://forygia.com/img/icon.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://forygia.com/img/icon.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://forygia.com/img/icon.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://forygia.com/img/icon.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://forygia.com/img/icon.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://forygia.com/img/icon.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://forygia.com/img/icon.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="50px 0 80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Datenschutzrichtlinie
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Diese Datenschutzrichtlinie ("Richtlinie") beschreibt, wie zonitypokies.com ("wir", "uns" oder "unser") die persönlichen Daten von Besuchern und Nutzern ("Sie" oder "Ihr") der zonitypokies.com-Website und der zugehörigen Dienste (zusammenfassend die "Dienste") sammelt, verwendet, weitergibt und schützt.
				<br />
				Durch die Nutzung unserer Dienste erklären Sie sich mit den in dieser Richtlinie beschriebenen Praktiken einverstanden. Wenn Sie mit dieser Richtlinie nicht einverstanden sind, nutzen Sie bitte unsere Dienste nicht.
				<br />
				<br />
				<Strong>
					1. Informationen, die wir sammeln
				</Strong>
				<br />
				<br />
				Wir können die folgenden Arten von Informationen sammeln:
				<br />
				<br />
				1.1 Persönliche Informationen: Wir können personenbezogene Daten wie Ihren Namen, Ihre E-Mail-Adresse, Ihre Postanschrift und andere Informationen erfassen, die Sie freiwillig angeben, wenn Sie mit unseren Diensten interagieren.
				<br />
				<br />
				1.2 Nutzungsdaten: Wir können nicht-personenbezogene Daten über Ihre Interaktionen mit unseren Diensten erfassen, einschließlich, aber nicht beschränkt auf Ihre IP-Adresse, den Browsertyp, das Betriebssystem und die besuchten Seiten. Wir können auch Cookies und ähnliche Technologien verwenden, um diese Informationen zu sammeln.
				<br />
				<br />
				<Strong>
					2. Wie wir Ihre Informationen verwenden
				</Strong>
				<br />
				<br />
				Wir verwenden Ihre Informationen für die folgenden Zwecke:
				<br />
				<br />
				2.1 Bereitstellung und Verbesserung von Dienstleistungen: Wir verwenden Ihre Daten, um unsere Dienste bereitzustellen, zu pflegen und zu verbessern, einschließlich der Beantwortung Ihrer Anfragen und Wünsche.
				<br />
				<br />
				2.2 Analytik: Wir verwenden Analysetools, um Nutzertrends und -präferenzen zu analysieren, was uns hilft, unsere Dienste zu verbessern.
				<br />
				<br />
				<Strong>
					3. Weitergabe Ihrer Informationen
				</Strong>
				<br />
				<br />
				Wir verkaufen, vermieten oder tauschen Ihre persönlichen Daten nicht an Dritte. Allerdings können wir Ihre Informationen in den folgenden Situationen weitergeben:
				<br />
				<br />
				3.1 Gesetzliche Anforderungen: Wir können Ihre Daten weitergeben, wenn dies durch Gesetze, Vorschriften, rechtliche Verfahren oder behördliche Anordnungen erforderlich ist.
				<br />
				<br />
				3.2 Schutz von Rechten: Wir können Ihre Daten weitergeben, wenn wir der Meinung sind, dass dies zum Schutz unserer Rechte, unserer Privatsphäre, unserer Sicherheit oder unseres Eigentums notwendig ist, oder um auf einen Notfall zu reagieren, der die Sicherheit einer Person bedroht.
				<br />
				<br />
				<Strong>
					4. Ihre Wahlmöglichkeiten
				</Strong>
				<br />
				<br />
				Sie haben folgende Wahlmöglichkeiten in Bezug auf Ihre persönlichen Daten:
				<br />
				<br />
				4.1 Zugang und Berichtigung: Sie können auf Ihre persönlichen Daten zugreifen und sie korrigieren, indem Sie uns unter contact@zonitypokies.com kontaktieren.
				<br />
				<br />
				4.2 Abmeldung: Sie können den Erhalt von Werbe-E-Mails von uns ablehnen, indem Sie die Anweisungen in diesen E-Mails befolgen.
				<br />
				<br />
				<Strong>
					5. Sicherheit
				</Strong>
				<br />
				<br />
				Wir setzen angemessene administrative, technische und physische Maßnahmen ein, um Ihre Daten zu schützen. Keine Methode der Übertragung über das Internet oder der elektronischen Speicherung ist jedoch völlig sicher, und wir können keine absolute Sicherheit garantieren.
				<br />
				<br />
				<Strong>
					6. Datenschutz für Kinder
				</Strong>
				<br />
				<br />
				Unsere Dienste sind nicht für Personen unter 13 Jahren bestimmt. Wenn Sie glauben, dass wir versehentlich Daten von einem Kind unter 13 Jahren erfasst haben, kontaktieren Sie uns bitte, und wir werden diese Daten umgehend löschen.
				<br />
				<br />
				<Strong>
					7. Änderungen an dieser Datenschutzrichtlinie
					<br />
					<br />
					Wir k
				</Strong>
				önnen diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Bitte überprüfen Sie diese Richtlinie regelmäßig, um sich über unsere Datenpraktiken zu informieren.
				<br />
				<br />
				<Strong font-weight="bold">
					8. Kontaktieren Sie uns
				</Strong>
				<br />
				<br />
				Wenn Sie Fragen, Bedenken oder Wünsche bezüglich dieser Datenschutzrichtlinie oder unserer Datenpraktiken haben, kontaktieren Sie uns bitte unter info@forygia.com
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});